import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAddress } from '@thirdweb-dev/react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import supabase from '../supabaseClient';

const QuoteModal = ({ show, handleClose }) => {
  const [quote, setQuote] = useState('');
  const [userData, setUserData] = useState(null);
  const address = useAddress();

  useEffect(() => {
    const fetchUserData = async () => {
      if (address) {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('wallet_address', address)
          .single();

        if (error) {
          console.error('Error fetching user data:', error);
        } else {
          setUserData(data);
        }
      }
    };

    fetchUserData();
  }, [address]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (quote.length < 100) {
      toast.warning('Hey Mfer! Your story needs to be at least 100 characters...', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Check if the quote already exists in Supabase
    try {
      const { data: existingQuote, error } = await supabase
        .from('quotes')
        .select('text')
        .ilike('text', quote.toLowerCase());

      if (error) {
        throw error;
      }

      if (existingQuote.length > 0) {
        toast.warning('Yo Mfer! That story\'s already been sent before. Try submitting something fresh and spicy this time!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      
    } catch (error) {
      console.error("Error checking for existing quote: ", error.message);
      toast.error(`Error checking quote: ${error.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    handleClose();

    try {
      const { data, error } = await supabase
        .from('quotes')
        .insert([{ text: quote, author: address, approved: false, created_at: new Date() }]);

      if (error) {
        throw error;
      }

      setQuote('');
      console.log("Quote added to Supabase", data);
    } catch (error) {
      console.error("Error adding quote: ", error.message);
      toast.error(`Error sending quote: ${error.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleQuoteChange = (e) => {
    if (e.target.value.length <= 280) {
      setQuote(e.target.value);
    } else {
      toast.warning('Quote must be 280 characters or less.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 7)}...${address.slice(-5)}`;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header style={{paddingTop:'0px', marginBottom:'0.5em', border:'0px'}}>
          <Modal.Title>Submit your Crypto Story</Modal.Title>
        </Modal.Header>
        <Modal.Body>

              {!userData?.twitter_handle && (
                <div className="alert alert-info" style={{ border: '1px solid #bee5eb', backgroundColor: '#d1ecf1', color: 'black', padding: '0.5em', borderRadius: '0.25em', lineHeight:'1.25em' }}>
                  {/* Mostrar solo si no hay cuenta de Twitter conectada */}
                  <u>Connect your Twitter account to boost your chances of winning prizes.</u>

                  {/* Mostrar este texto solo si NO hay wallet conectada */}
                  {!address && (
                    <>
                      <br />
                      <i>Connect your wallet to be eligible for prizes (you'll appear as anonymous) but with fewer chances of winning prizes.</i>
                    </>
                  )}

                  {/* Mostrar este mensaje solo si no hay cuenta de Twitter ni wallet conectada */}
                  {!userData?.twitter_handle && !address && (
                    <>
                      <br /><br />
                      You can still submit stories, <span style={{color:'red'}}>but you won't be eligible to win prizes.</span>
                    </>
                  )}
                </div>
              )}


            {userData ? (
              <div className="mb-0">
                {address && (
                  <p style={{ fontSize: '1.3em' }}><i>Wallet Connected: {shortenAddress(address)}</i></p>
                )}
                {userData.twitter_handle ? (
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: '1.3em' }}>Twitter Linked: </p>
                    <a href={userData.profile_url} target="_blank" rel="noopener noreferrer" className="twitter-link">
                      <div className="flex items-center p-2 rounded-md twitter-box">
                        <img src={userData.profile_image} alt="Profile" className="w-12 h-12 mr-2 rounded-full" style={{ width: '30px', height: 'auto', position: 'relative', top: '-10px', marginLeft: '0.7em' }} />
                        <p className="text-lg font-semibold text-black">{userData.twitter_handle}</p>
                      </div>
                    </a>
                  </div>
                ) : null}
              </div>
            ) : (
              address && <p style={{ fontSize: '1.3em' }}>Wallet Connected: {shortenAddress(address)}</p>
            )}

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formQuote">
              <Form.Control
                as="textarea"
                rows={3}
                value={quote}
                onChange={handleQuoteChange}
                placeholder="Enter your story here"
                required
              />
              <Form.Text muted style={{ fontSize: '1.1em' }}>
                {quote.length}/280 characters
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Submit Story
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuoteModal;
